@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

* {
  padding: 0;
  margin: 0;
}
html {
  font-size: 16px;
}
body {
  font-family: "Urbanist","Bebas", sans-serif;
  /* background-color: #151313; */
}
.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
}
/* .back-video{
  position: absolute;
right: 0;
bottom: 0;
z-index: -1;
} */
.showcase {
  position: relative;

  align-items: center;
  justify-content: center;
  text-align: center;
}
.video-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  overflow: hidden;
  background: 
    url("https://traversymedia.com/downloads/cover.jpg") no-repeat center
    center/center;
}
.video-container video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%;
  width: 100%; */

}
.login-bg {
  /* background-image: url("../assets/loginbg.gif");  */
  background-size: cover, auto; 
  background-position: center; 
  background-repeat: no-repeat, repeat; 
  background-color: #151313;
  /* position: relative; */
}
.login-bg-1 {
  /* background-image: url("../assets/loginbg.gif");  */
  background-size: cover, auto; 
  background-position: center; 
  background-repeat: no-repeat, repeat; 
  background-color: black;
  /* position: relative; */
}


.login-bg-1:before {
  content: ""; /* Add content for the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url('../assets/lines1.png');
  background-position: center;
  background-repeat: no-repeat;
}

.login-bg:before {
  content: ""; /* Add content for the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url('../assets/lines.png');
  background-position: center;
  background-repeat: no-repeat;
}
.button-pos {
  position: relative; 
  z-index: 2;
}
.gradient-box{
  border:2px solid white;
border-radius: 2rem;
box-sizing: border-box;
z-index: 1;
}
.gradient-box:hover {
  border: unset;
  background: radial-gradient(
        circle at 100% 100%,
        #151313 0,
        #151313 30px,
        transparent 30px
      )
      0% 0%/32px 32px no-repeat,
    radial-gradient(circle at 0 100%, #151313 0, #151313 30px, transparent 30px)
      100% 0%/32px 32px no-repeat,
    radial-gradient(circle at 100% 0, #151313 0, #151313 30px, transparent 30px) 0%
      100%/32px 32px no-repeat,
    radial-gradient(circle at 0 0, #151313 0, #151313 30px, transparent 30px) 100%
      100%/32px 32px no-repeat,
    linear-gradient(#151313, #151313) 50% 50% / calc(100% - 4px) calc(100% - 64px)
      no-repeat,
    linear-gradient(#151313, #151313) 50% 50% / calc(100% - 64px) calc(100% - 4px)
      no-repeat,
    linear-gradient(268deg, #ff7d05 0%, transparent 70%),
    radial-gradient(at 100% 100%, #e02424 0%, transparent 70%),
    radial-gradient(at 100% 0%, #3354f4 0%, transparent 70%),
    radial-gradient(at 0% 0%, #01fff4 0%, transparent 70%), #6552fe;
  border-radius: 2rem;
  box-sizing: border-box;
}
.gradient-btn{
  color: white;
}
.gradient-box:hover .gradient-btn{
  background: linear-gradient(#6552FE, #01FFF4);
  color: black;
  font-weight: 400;

;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1.875rem black inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #f3f3f3 !important;
}
.btn-primary-gradient {
  /* background: conic-gradient(from 0deg at 74.93% -6%, #6552FE 161.39362335205078deg, #01FFF4 360deg); */
  background: conic-gradient(
    from 0deg at 74.93% -30%,
    #6552fe 230.393623deg,
    #01fff4 360deg
  );
}

@media (max-width: 1399px) {
  html {
    font-size: 14px;
  }
}
.MuiList-root {
  background: #4b4b4b;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 20px;
  background: #0805023a;
}

::-webkit-scrollbar-thumb:hover {
  background: #0805023a;
}
.border-user-btn {
  border: 2px solid
    linear-gradient(
      180deg,
      #6552fe -23.85%,
      #01fff4 56.55%,
      rgba(30, 30, 30, 0.63) 100%
    );
}
.gradient-border {
  position: relative;
  padding: 10px; /* Adjust padding as needed */
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    #6552fe -23.85%,
    #01fff4 56.55%,
    rgba(30, 30, 30, 0.63) 100%
  );
  border-radius: inherit;
}
.button-bor {
  border: 3px solid transparent;
  border-image: linear-gradient(
    180deg,
    #6552fe -23.85%,
    #01fff4 56.55%,
    rgba(30, 30, 30, 0.63) 100%
  );
  border-image-slice: 1;
  border-radius: 20px; /* Adjust the value to change the roundness */
}


.btn {
  /* padding: 0 40px; */
  overflow: hidden;
  font-weight: 700;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  /* font-size: var(--bs-body-font-size);
  line-height: var(--bs-body-line-height);
  border-radius: var(--bs-border-radius-lg); */
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.15, 0.85, 0.31, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.15, 0.85, 0.31, 1);
  transition: transform 0.6s cubic-bezier(0.15, 0.85, 0.31, 1);
  transition: transform 0.6s cubic-bezier(0.15, 0.85, 0.31, 1), -webkit-transform 0.6s cubic-bezier(0.15, 0.85, 0.31, 1);
}
.btn .btn_text {
  /* position: relative; */
  /* display: inline-block; */
  padding: 12px 0 14px;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.15, 0.85, 0.31, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.15, 0.85, 0.31, 1);
  transition: transform 0.5s cubic-bezier(0.15, 0.85, 0.31, 1);
  transition: transform 0.5s cubic-bezier(0.15, 0.85, 0.31, 1), -webkit-transform 0.5s cubic-bezier(0.15, 0.85, 0.31, 1);
}
.gradient-text {
  background: linear-gradient(84deg, #6552FE 0%, #01FFF4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn .btn_text:before {
  top: 100%;
  left: 50%;
  width: 100%;
  display: block;
  position: absolute;
  height: 150px;
  content: attr(data-text);
  background: linear-gradient(84deg, #6552FE 0%, #01FFF4 100%);
  font-size: 25px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media screen and (max-width: 1024px){
  .btn .btn_text:before{
    font-size: 18px;
    height: 120px;
  }
}
.btn .btn_icon {
  margin-left: 8px;
}
.btn:hover {
  font-size: 20px;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}
.btn:hover .btn_text {
  -webkit-transform: translateY(-76%);
          transform: translateY(-76%);
}
.bg-icons{
  border-radius: 9999px;
background: linear-gradient(180deg, rgba(175, 0, 0, 0.20) -23.85%, rgba(198, 131, 0, 0.20) 15.66%, rgba(30, 30, 30, 0.20) 100%);
backdrop-filter: blur(7.5px);
-webkit-backdrop-filter: blur(7.5px);
}
.slick-dots li.slick-active button::before {
  color:White !important;
  border:1px solid #6552FE;
  border-radius: 9999px;
}
.slick-dots li button::before {
  color:White !important;
}
img {
  margin: auto;
}
.slick-slide > div {
  display: grid;
  place-items: center;
  /* width: 80%; */
  margin-top: 50px;
  margin: auto;
  /* height: 300px; */
  padding: 0px;
  /* background: red; */
}
.contentContainer {
  display: flex;
  justify-content: center !important;
  }
  .border-wrap {
    /* max-width: 250px; */
    padding: 6px;
    position: relative;
    background: linear-gradient(#6552fe, #01fff4, #1e1e1e);
    padding: 2.5px;
  }
  .module {
    background: #0b0a0a;
  }
  .gradient-text-price {
    background: conic-gradient(from 0deg at 74.93% -6%, #6552FE 161.39362335205078deg, #01FFF4 360deg);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Make sure the video is behind your content */
}

.flex-1 {
  position: relative; /* Ensure content is on top of video */
  z-index: 1;
}

.login-bg {
  background-color: #151313; /* Fallback color in case video doesn't load */
}
