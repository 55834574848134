.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-text {
    color: white;
    font-size: 10rem;
    font-weight: bold;
  }
  
  .main-navigation {
    z-index: 1100; /* Ensure MainNavigation is above the overlay */
  }